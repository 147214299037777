<!--
*  TTTech nerve-management-system
*  Copyright(c) 2024. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <login logo="/img/login.svg" image="/img/login-background.jpg">
    <template #login-actions>
      <div id="iiotValidateMFA">
        <v-form id="iiotValidateMFALeftPart" v-model="valid" @submit.prevent="validateMFA">
          <v-layout class="title" align-center="true" justify-center="true" fill-height="true" column>
            <h3>{{ $t('login.validateMFA.title') }}</h3>
            <v-divider />
          </v-layout>

          <v-text-field
            id="iiotValidateMFAInputCode"
            v-model="totpCode"
            :rules="[rules.totp]"
            :hint="$t('login.validateMFA.totpInputHint')"
            class="default-input-appearance"
            autofocus
          />

          <v-layout class="mt-15" align-center="true" justify-center="true" fill-height="true">
            <nerve-button
              id="iiotValidateMFAConfirmButton"
              data-cy="iiotValidateMFAConfirmButton"
              :disabled="isSubmitting || !valid || !isTOTPValid"
              :text="$t('login.validateMFA.confirmBtn')"
              type-of-btn="action"
              size="normal"
              class="mx-0"
              type="submit"
            />
          </v-layout>
        </v-form>
      </div>
    </template>
  </login>
</template>

<script>
import { Login, NerveButton } from 'nerve-ui-components';
import { VALIDATION_REGEX } from '@/constants';
import i18n from '@/i18n';
import NotificationBackgroundMixin from '@/mixins/notification.mixin';

export default {
  name: 'MultiFactorAuthenticationCodeForm',
  components: { Login, NerveButton },
  mixins: [NotificationBackgroundMixin],
  data: () => ({
    totpCode: '',
    valid: false,
    isSubmitting: false,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('login.fieldIsRequired'),
        totp: (value) => VALIDATION_REGEX.TOTP.test(value) || this.$t('login.validateMFA.totpRegexMsg'),
      };
    },
    isTOTPValid() {
      return VALIDATION_REGEX.TOTP.test(this.totpCode);
    },
    getActiveNotification() {
      return this.$store.getters['notifications/getActiveNotification'];
    },
  },
  async created() {
    const mfa = this.$store.getters['auth/userMFA'];
    if (!mfa?.validationId) {
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: this.$t(`login.validateMFA.notLoggedIn`),
        color: 'red',
        showClose: true,
      });
      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, 1200);
    }
  },
  async destroyed() {
    await this.$store.dispatch('auth/removeMFA');
  },
  methods: {
    async validateMFA() {
      try {
        this.isSubmitting = true;
        const mfa = this.$store.getters['auth/userMFA'];
        await this.$store.dispatch('auth/validateMFA', {
          validationId: mfa?.validationId,
          authCode: this.totpCode,
        });
      } catch (e) {
        this.$log.debug(e);
        if (e.response.status === 404 || e.response.status === 429) {
          setTimeout(() => {
            this.$router.push({ name: 'Login' });
          }, 1200);
          return;
        }
        this.isSubmitting = false;
        return;
      }

      try {
        await this.$store.dispatch('auth/is_user_logged');
        await this.$store.dispatch('utils/_api_request_handler/show_permanent_toast', false);

        // eslint-disable-next-line prefer-destructuring
        i18n.locale = JSON.parse(localStorage.getItem('userDetails')).preferredLanguage.split('_')[0];

        this.$mqtt.connect();

        if (this.$route.query.redirect) {
          await this.$router.push({ path: this.$route.query.redirect }).catch(() => {});
        } else {
          await this.$router.push({ name: 'Nodes', query: { tab: '0' } }).catch(() => {});
          await this.showNotification();
          // eslint-disable-next-line prefer-destructuring
          i18n.locale = JSON.parse(localStorage.getItem('userDetails')).preferredLanguage.split('_')[0];
        }
      } catch (e) {
        this.$log.debug(e);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#iiotValidateMFALeftPart {
  padding: 50px;
  .title {
    > h3 {
      text-align: center;
    }
  }
}
</style>
